<template>
  <div class="story-page-two-container">
    <StoryPage :bgImg="bgImg"/>
  </div>
</template>

<script>
import StoryPage from "@/components/Course/CoursePage/StoryPage";
export default {
  name: "StoryPageTwo",
  components: {
    StoryPage,
  },
  data() {
    return {
      bgImg: require("@/assets/img/13-Video/chapter-08/lesson-63-video-2.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.story-page-two-container {
  width: 100%;
  height: 100%;
}
</style>