<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList" :menuSize="`small`" :menuItems="4"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/6-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/6-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/6-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/7-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/7-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/7-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/8-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/8-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/8-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/9-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/9-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/9-3.svg"),
            showObj: true,
          },
        ],
      ],

      answerList: [
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/chizi.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/6-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/6-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/6-puzzle.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/chizi.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/7-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/7-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/7-puzzle.svg"),
        },
        {
          id: 2,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/wushi.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/8-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/8-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/8-puzzle.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/wushi.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/9-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/9-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/9-puzzle.svg"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>
