<template>
  <div class="game-container">
    <RecognitionPinyinBasketBallGame
      :answerList="answerList"
      :optionList="optionList"
      :audioList="audioList"
      :totalStarNumber="8"
    />
  </div>
</template>

<script>
import RecognitionPinyinBasketBallGame from "@/components/Course/GamePage/RecognitionPinyinBasketBallGame";
export default {
  data() {
    return {
      lessonId: 8,
      answerList: [
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 4,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 3,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 4,
          sound: "",
          showObj: true,
        },
      ],

      optionList: [
        [
        {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yi.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yin.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-ying.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-ye.svg"),
            showObj: true,
          },
        ],
        [
        {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-ye.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yin.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yue.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yuan.svg"),
            showObj: true,
          },
        ],
        [
        {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yue.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yu.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yuan.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-ye.svg"),
            showObj: true,
          },
        ],
        [
        {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yuan.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-ye.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yue.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yi.svg"),
            showObj: true,
          },
        ],
        [
        {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yin.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-ye.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yue.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-wu.svg"),
            showObj: true,
          },
        ],
        [
        {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yu.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-wu.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yue.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yi.svg"),
            showObj: true,
          },
        ],
        [
        {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yi.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-ying.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yin.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yu.svg"),
            showObj: true,
          },
        ],
        [
        {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-ying.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yin.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-yi.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/chapter-08/basketball-wu.svg"),
            showObj: true,
          },
        ],
        
      ],
      audioList:[
        require("@/assets/audio/L1/2-Pinyin/yi.mp3"),
        require("@/assets/audio/L1/2-Pinyin/yuan.mp3"),
        require("@/assets/audio/L1/2-Pinyin/yu.mp3"),
        require("@/assets/audio/L1/2-Pinyin/ye.mp3"),
        require("@/assets/audio/L1/2-Pinyin/yin.mp3"),
        require("@/assets/audio/L1/2-Pinyin/yue.mp3"),
        require("@/assets/audio/L1/2-Pinyin/ying.mp3"),
        require("@/assets/audio/L1/2-Pinyin/wu.mp3"),
      ]
    };
  },
  // mounted () {
  //   let newArr=[]
  //   for (let i = 0; i < 7; i++) {
  //     const ele =  [{
  //           id: 1,
  //           bottomImg: require("@/assets/img/16-GAMES/G45-basketball/backetball-b.svg"),
  //           showObj: true,
  //         },
  //         {
  //           id: 2,
  //           bottomImg: require("@/assets/img/16-GAMES/G45-basketball/backetball-p.svg"),
  //           showObj: true,
  //         },
  //         {
  //           id: 3,
  //           bottomImg: require("@/assets/img/16-GAMES/G45-basketball/backetball-m.svg"),
  //           showObj: true,
  //         },
  //         {
  //           id: 4,
  //           bottomImg: require("@/assets/img/16-GAMES/G45-basketball/backetball-f.svg"),
  //           showObj: true,
  //         },]
  //         newArr.push(ele)
  //   }
  //   //  return newArr
  //   this.optionList = newArr
  //   console.log(this.optionList,'qqqqqqqqqqqqq');
   
    
  // },
  components: {
    RecognitionPinyinBasketBallGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>