<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="8"/>
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Pīnyīn (bā) zhěngtǐ rèn dú",
        hanzi: "拼音（八） 整体认读",
        english: "Pinyin 8 | Final reading",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-63-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>