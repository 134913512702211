<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" :layout="`small-medium`"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'yellowColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          english: "wu",
          image: require("@/assets/img/14-Pinyin/chapter-08/pinyin-wuyun.svg"),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          english: "yu",
          image: require("@/assets/img/14-Pinyin/chapter-08/pinyin-yu.svg"),
        },
        {
          bgColor: 'yellowColor',
          order: "four",
          isShowImg: "true",
          currentNum: 3,
          english: "ye",
          image: require("@/assets/img/14-Pinyin/chapter-08/pinyin-yezi.svg"),
        },
        {
          bgColor: 'yellowColor',
          order: "five",
          isShowImg: "true",
          currentNum: 4,
          english: "yue",
          image: require("@/assets/img/14-Pinyin/chapter-08/pinyin-yinyue.svg"),
        },

        {
          bgColor: 'yellowColor',
          order: "three",
          isShowImg: "true",
          currentNum: 5,
          english: "yin",
          image: require("@/assets/img/14-Pinyin/chapter-08/pinyin-yin.svg"),
        },
        {
          bgColor: 'yellowColor',
          order: "three",
          isShowImg: "true",
          currentNum: 6,
          english: "yun",
          image: require("@/assets/img/14-Pinyin/chapter-08/pinyin-yun.svg"),
        },
        {
          bgColor: 'yellowColor',
          order: "three",
          isShowImg: "true",
          currentNum: 7,
          english: "yuan",
          image: require("@/assets/img/14-Pinyin/chapter-08/pinyin-yuan.svg"),
        },
        {
          bgColor: 'yellowColor',
          order: "three",
          isShowImg: "true",
          currentNum: 8,
          english: "ying",
          image: require("@/assets/img/14-Pinyin/chapter-08/pinyin-yingwu.svg"),
        },

      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
