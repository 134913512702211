<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList" :menuSize="`small`" :menuItems="5"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/1-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/1-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/1-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/2-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/2-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/2-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/3-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/3-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/3-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/4-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/4-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/4-3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/5-1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/5-2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/5-3.svg"),
            showObj: true,
          },
        ],
      ],

      answerList: [
        {
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/zhi3.mp3"),
          id: 2,
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/1-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/1-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/1-puzzle.svg"),
        },
        {
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/yizi.mp3"),
          id: 1,
          isCorrect: false,
          // type: 1,
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/2-correct.svg"),
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/2-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/2-puzzle.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/cixiu.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/3-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/3-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/3-puzzle.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/sishi.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/4-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/4-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/4-puzzle.svg"),
        },
        {
          id: 2,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/sishi.mp3"),
          isCorrect: false,
          type: 1,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/5-correct.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/5-base.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-63/5-puzzle.svg"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>
